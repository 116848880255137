/* eslint-disable no-undef */

/* eslint-disable no-useless-catch */
import {
  Box,
  Heading,
  Flex,
  Stack,
  HStack,
  VStack,
  Avatar,
  Divider,
  Text,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Image,
  Spinner,
  Center,
  AspectRatio,
  SimpleGrid,
  useToast,
  Select,
} from '@chakra-ui/react';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import Webcam from 'react-webcam';
import { auth } from '../../Config/firebase';
import * as geolib from 'geolib';
import useUserStore from '../../Hooks/Zustand/Store';
import { createHris, createHrisFirebase, readHris } from '../../Api/Hris';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import { IoRefreshSharp } from 'react-icons/io5';
import { getCollectionFirebase, getSingleDocumentFirebase } from '../../Api/firebaseApi';
import { decryptToken } from '../../Utils/encrypToken';
import UploadFileToDropbox from '../../Utils/UploadFileToDropbox';
import { Base64ToBlob } from '../../Utils/Base64ToBlob';
import { Timestamp } from 'firebase/firestore';
import { convertTimestampToDate } from '../../Utils/timeUtil';

const HrisAttendanceLive = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isDesktop, setDesktop] = React.useState(window.innerWidth > 600);
  const webCamRef = useRef(null);
  const toast = useToast();
  const updateMedia = () => {
    setDesktop(window.innerWidth > 600);
  };
  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  const [inputAttendace, setInputAttendance] = useState({});
  const [myAttendance, setMyAttendance] = useState([]);
  const [imageData, setImageData] = useState('');
  const [cameraActive, setCameraActive] = useState(false);
  const [inOut, setInOut] = useState([]);
  const [locPermission, setLocPermission] = useState(false);
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [isSelecting, setIsSelecting] = useState(false);
  const [selectOffice, setSelectOffice] = useState([]);
  const [timeIn, setTimeIn] = useState('');
  const [timeOut, setTimeOut] = useState('');
  const [dataOffice, setDataOffice] = useState([]);
  const [progress, setProgress] = useState(0)
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const month = moment().format('MM');
  const year = moment().format('YYYY');
  const sevenDays = moment().subtract(7, 'days').format('YYYY-MM-DD');
  const sevenDaysFirebase = moment().subtract(7, 'days').startOf('day').toDate();

  const globalState = useUserStore();

  const getDataOffice = async () => {
    const conditions = [
      { field: 'companyId', operator: '==', value: globalState?.currentCompany },
      { field: 'employee', operator: 'array-contains', value: globalState?.uid },
    ];
    try {
      const res = await getCollectionFirebase('offices', conditions);
      setSelectOffice(res);
      setDataOffice(res[0]);

      setTimeIn(moment(`${res[0]?.startHour}`, 'h:mm').format('h:mm a'));
      setTimeOut(moment(`${res[0]?.endHour}`, 'h:mm').format('h:mm a'));

      //office location check
      // setLongitudeOffice(parseInt(res[0]?.longitude));
      // setLatitudeOffice(parseInt(res[0]?.latitude));
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        duration: 3000,
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  const getDataFirebase = async () => {
    const conditions = [
      { field: 'companyId', operator: '==', value: globalState?.currentCompany },
      { field: 'projectId', operator: '==', value: globalState?.currentProject },
      { field: 'createdAt', operator: '>=', value: Timestamp.fromDate(sevenDaysFirebase) },
      { field: 'userId', operator: '==', value: globalState?.uid },
    ]
    try {
      const get = await getCollectionFirebase('attendances', conditions)
      if (get?.length > 0) {
        const attendanceToday = get?.find((x) => convertTimestampToDate(x?.createdAt) === moment().format('YYYY-MM-DD'))
        setMyAttendance(attendanceToday)
        setInOut(get)
      } else {
        setInOut([])
      }
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        duration: 3000,
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      })
    }
  }

  const getData = async () => {
    const session = {
      headers: globalState?.authFirebaseToken,
      companyId: globalState?.currentCompany,
      projectId: globalState?.currentProject,
    };

    try {
      const { data } = await readHris(`attendance/employee?month=${month}&year=${year}`, session);
      const today = data?.find((x) => x?.periode === moment().format('YYYY-MM-DD') && x?.company_uid === globalState?.currentCompany);
      const week = data?.filter((x) => moment(x.periode, 'YYYY-MM-DD').isSameOrAfter(sevenDays, 'days') && x?.company_uid === globalState?.currentCompany);
      week?.sort((a, b) => new Date(b.periode) - new Date(a.periode));

      setMyAttendance(today);
      setInOut(data);
      setInOut(week);
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        duration: 3000,
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    getData();
    getLocation();

    return () => {
      setMyAttendance([]);
      setInOut([]);
    };
  }, [globalState?.currentCompany]);

  const isCameraActive = (data) => {
    if (data.active === true) {
      setCameraActive(true);
    }
  };

  const handleOnClose = () => {
    setCameraActive(false);
    setLoadingSubmit(false);
    setImageData('');
    onClose();
  };


  const getLocation = () => {
    navigator.geolocation.getCurrentPosition(function (position) {
      setLat(position.coords.latitude);
      setLng(position.coords.longitude);
      setLocPermission(true);
    })
  };


  const capture = async () => {
    const imageSrc = webCamRef.current.getScreenshot();
    setImageData(imageSrc);
  };

  const handleClick = () => {
    if (!dataOffice || !dataOffice.latitude || !dataOffice.longitude) {
      toast({
        status: 'error',
        description:
          'The office data is incomplete. Please set up the office data first in setting menu. ',
        duration: 2000,
      });
      return; // Hentikan eksekusi selanjutnya
    }
    globalState?.setIsLoading(true);
    try {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const radiusRange = geolib.getDistance(
            {
              latitude: position?.coords?.latitude,
              longitude: position?.coords?.longitude,
            },
            {
              latitude: dataOffice?.latitude,
              longitude: dataOffice?.longitude,
            }
          );
  
          if (radiusRange > parseInt(dataOffice?.radius)) {
            toast({
              status: 'error',
              description: 'Your location very far from your office',
              duration: 2000,
            });
          } else if (locPermission === false) {
            toast({
              status: 'error',
              description: 'please active your location.',
              duration: 2000,
            });
          } else {
            onOpen();
          }
        },
        () => {
          toast({
            title: 'Deoapp.com',
            duration: 3000,
            description: 'your position not found',
            status: 'error',
            position: 'top-right',
            isClosable: true,
          });
        }
      );
      
    } catch (error) {
      throw new Error(error);
    } finally {
      globalState?.setIsLoading(false);
    }

  };


  const onSubmit = async () => {
    setLoadingSubmit(true);

    const session = {
      headers: globalState?.authFirebaseToken,
      companyId: globalState?.currentCompany,
      projectId: globalState?.currentProject,
    };


    try {
      const token = await getSingleDocumentFirebase('token', 'dropbox');
      const decryptResult = decryptToken(`${token?.access_token}`);


      const parentPath = `/${globalState?.currentCompany}/${globalState?.currentProject}/hris/live`;

      const convertImage = Base64ToBlob(imageData, 'image/jpeg')

      const response = await UploadFileToDropbox(convertImage, parentPath, decryptResult, setProgress, progress)

      if (response.link) {
        const linkImage = response?.link
        const currentLng = lng !== null ? lng : 0;
        const currentLat = lat !== null ? lat : 0;

        const datax = {
          image: linkImage,
          latitude: currentLat.toString(),
          longitude: currentLng.toString(),
          office_uid: dataOffice?.id,
        };

        const [hrisResult, firebaseResult] = await Promise.all([
          createHris('attendance', session, datax),
          createHrisFirebase(globalState, 'attendances', datax)
        ]);

        // Combine results as needed
        const combinedResult = {
          hris: hrisResult,
          firebase: firebaseResult
        };

        console.log('Combined Results:', combinedResult);
      }

    } catch (error) {
      toast({
        title: 'Deoapp.com',
        duration: 3000,
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    } finally {
      setLoadingSubmit(false);
      setInputAttendance('');
      getData();
      setImageData('');
      handleOnClose();
    }
  };



  const handleSelectOffice = (value) => {
    const findOffice = selectOffice?.find((x) => x.id === value);

    setDataOffice(findOffice);

    setTimeIn(moment(`${findOffice?.startHour}`, 'h:mm').format('h:mm a'));
    setTimeOut(moment(`${findOffice?.endHour}`, 'h:mm').format('h:mm a'));
    setIsSelecting(false);
  };

  useEffect(() => {
    // getData();
    setCameraActive(false);
    // getDataFirebase()

  }, []);

  useEffect(() => {
    getDataOffice();


    return () => {
    };
  }, [globalState?.currentCompany]);

  return (
    <Flex
      p={[1, 1, 5]}
      minH={'60vh'}
      overflow={'hidden'}
      flexDir={isDesktop ? 'row' : 'column'}
      w={'full'}
      mt={10}
    >
      <VStack
        spacing={3}
        w={isDesktop ? '60%' : '100%'}
        h={'min-content'}
        justifyContent={'center'}
        mt={isDesktop ? 0 : 2}
      >
        <HStack spacer="1" textAlign="left">
          <Avatar
            size={['sm', 'md', 'md']}
            src={auth?.currentUser?.photoURL || globalState?.name}
          />
          <Heading size={['md', 'lg', 'lg']} alignSelf="center">
            {globalState?.name}
          </Heading>
        </HStack>

        <Divider
          mt={3}
          mb={3}
          w={'lg'}
          borderColor={'black'}
          borderWidth={'0.5px'}
        />

        <Stack spacing={1} align={'center'}>
          {isSelecting ? (
            <Select
              bg={'white'}
              size={'sm'}
              defaultValue={dataOffice?.id}
              onChange={(e) => handleSelectOffice(e.target.value)}
            >
              {selectOffice?.map((office) => (
                <option key={office?.id} value={office?.id}>
                  {office?.title}
                </option>
              ))}
            </Select>
          ) : (
            <Text color="gray.500" textAlign={'center'}>
              {dataOffice?.title}
            </Text>
          )}
          <Text
            fontSize={12}
            cursor={'pointer'}
            _hover={{ textDecor: 'underline' }}
            onClick={() => setIsSelecting(true)}
          >
            Change office
          </Text>
        </Stack>
        <Text color="gray.500" textAlign={'center'}>
          Shift: Regular Office Hour [{timeIn} - {timeOut}]
        </Text>
        <Text color="gray.500">{moment().format('DD-MM-YYYY')}</Text>
        <br />
        <Flex
          w={{ base: '20rem', lg: '30rem' }}
          align="center"
          justify={'space-around'}
        >
          <Box>
            <Text textAlign="center" fontWeight={500} mb={5}>
              Clock in
            </Text>
            <Heading as="h3" size="lg" color="green.600">
              <Center mb={5}>
                <AspectRatio boxSize={'75'} ratio={1}>
                  <Image
                    shadow="md"
                    rounded={'full'}
                    src={myAttendance?.image_in}
                    fallbackSrc="https://via.placeholder.com/150"
                  />
                </AspectRatio>
              </Center>
              {myAttendance?.clock_in && myAttendance !== undefined ? (
                <Text>
                  {moment.parseZone(myAttendance?.clock_in || 0)?.utcOffset(myAttendance?.gmt_offset_in || 0)?.format('HH:mm:ss')}
                </Text>
              ) : (
                <Text> --:--:--</Text>
              )}
            </Heading>
          </Box>
          <Box>
            <Text textAlign="center" fontWeight={500} mb={5}>
              Clock out
            </Text>
            <Heading as="h3" size="lg" color="red.600">
              <Center mb={5}>
                <AspectRatio boxSize={'75'} ratio={1}>
                  <Image
                    shadow="md"
                    rounded={'full'}
                    src={myAttendance?.image_out}
                    fallbackSrc="https://via.placeholder.com/150"
                  />
                </AspectRatio>
              </Center>
              <Text>
                {myAttendance?.clock_out ? (
                  <Text>
                    {moment
                      .parseZone(myAttendance?.clock_out || 0)?.utcOffset(myAttendance?.gmt_offset_out || 0)?.format('HH:mm:ss')}
                  </Text>
                ) : (
                  <Text>--:--:--</Text>
                )}
              </Text>
            </Heading>
          </Box>
        </Flex>

        <br />

        {locPermission ? (
          <Stack>
            {myAttendance?.clock_in === undefined ? (
              <Button colorScheme="orange" onClick={handleClick}>
                Clock In
              </Button>
            ) : (
              <>
                {myAttendance?.clock_out === null ? (
                  <Button colorScheme="orange" onClick={handleClick}>
                    Clock Out
                  </Button>
                ) : (
                  ''
                )}
              </>
            )}
          </Stack>
        ) : (
          <Stack>
            <Text color={'gray.600'} fontSize="sm">
              Find your location ...
            </Text>
          </Stack>
        )}
      </VStack>

      <SimpleGrid
        bgColor={'lightgrey'}
        columns={1}
        spacing="20px"
        overflowY={'scroll'}
        w={isDesktop ? '40%' : 'full'}
        p={[1, 1, 5]}
        rounded={5}
        shadow={'base'}
        mt={isDesktop ? 0 : 10}
      >
        <Text
          fontFamily="Poppins, sans-serif"
          fontSize="lg"
          color="#black"
          fontWeight="bold"
          m={[1, 2, 2]}
        >
          Last 7 Days
        </Text>
        {inOut?.length > 0 ? (
          inOut?.map((item, index) => (
            <Stack
              shadow={'md'}
              borderRadius={'md'}
              bgColor={'white'}
              key={index}
              m={[1, 2, 2]}
            >
              <Stack
                py={2}
                bgColor={'orange'}
                alignItems="center"
                justifyContent={'center'}
                roundedTopLeft="md"
                roundedTopRight="md"
              >
                <Text fontWeight={500}>
                  {moment.unix(item?.period).format('dddd, DD MMMM YYYY') === 'Invalid date' ? moment.parseZone(item?.clock_in || 0).local().format('dddd, D MMMM YYYY') : moment.unix(item?.period).format('dddd, DD MMMM YYYY')}
                </Text>
              </Stack>
              <HStack
                justify="space-around"
                p={3}
                roundedBottomLeft="md"
                roundedBottomRight="md"
              >
                <Stack
                  w="8.5rem"
                  flexDir={{ base: 'column', lg: 'row' }}
                  justify="space-between"
                  align="center"
                >
                  <Avatar
                    size="md"
                    src={
                      item?.image_in ??
                      'https://media.tarkett-image.com/large/TH_25121916_25131916_25126916_25136916_001.jpg'
                    }
                  />
                  <Box align="center" fontWeight="semibold">
                    <Text color="gray.500">Start Time</Text>
                    {item?.clock_in !== null ? (
                      <Text color="green.600">
                        {moment
                          .parseZone(item?.clock_in || 0)
                          .local()
                          .format('HH:mm:ss') ?? '--:--:--'}
                      </Text>
                    ) : (
                      <Text color="green.600">--:--:--</Text>
                    )}
                  </Box>
                </Stack>
                <Center height="50px">
                  <Divider
                    orientation="vertical"
                    borderColor={'black'}
                    borderWidth={'0.5px'}
                  />
                </Center>
                <Stack
                  w="8.5rem"
                  flexDir={{ base: 'column', lg: 'row' }}
                  justify="space-between"
                  align="center"
                >
                  <Avatar
                    size="md"
                    src={
                      item?.image_out ??
                      'https://media.tarkett-image.com/large/TH_25121916_25131916_25126916_25136916_001.jpg'
                    }
                  />
                  <Box align="center" fontWeight="semibold">
                    <Text color="gray.500">End Time</Text>
                    {item?.clock_out !== null ? (
                      <Text color="red.600">
                        {moment
                          .parseZone(item?.clock_out || 0)?.local()?.format('HH:mm:ss') ?? '--:--:--'}
                      </Text>
                    ) : (
                      <Text color="red.600">--:--:--</Text>
                    )}
                  </Box>
                </Stack>
              </HStack>
              <Stack p={3}>
                <Text color={'gray.500'} fontSize={'xs'}>Company id: {item?.company_uid}</Text>
              </Stack>
            </Stack>
          ))
        ) : (
          <Center>
            <Text>No data in last 7 days</Text>
          </Center>
        )}
      </SimpleGrid>

      <Modal isOpen={isOpen} onClose={handleOnClose} size={'lg'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Attendance</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            {imageData !== '' ? (
              <Stack spacing={3}>
                <Image
                  w={'auto'}
                  h="auto"
                  objectFit={'contain'}
                  src={imageData}
                />
                <HStack alignItems={'center'} justifyContent="center">
                  <DynamicButton
                    color={'orange'}
                    icon={IoRefreshSharp}
                    variant={'solid'}
                    onClick={() => setImageData('')}
                    title="reload"
                    action="custom"
                  />
                  <DynamicButton
                    color={'orange'}
                    icon="md"
                    variant="solid"
                    onClick={() => onSubmit(inputAttendace)}
                    action="create"
                    title="submit"
                    isLoading={loadingSubmit}
                  />
                </HStack>
              </Stack>
            ) : (
              <Stack spacing={3}>
                <Webcam
                  audio={false}
                  ref={webCamRef}
                  screenshotFormat="image/jpeg"
                  mirrored={true}
                  onUserMedia={(e) => isCameraActive(e)}
                />

                <Box mt={4} w={'100%'}>
                  {cameraActive ? (
                    <Button
                      w={'100%'}
                      colorScheme="orange"
                      mr={3}
                      isLoading={loadingSubmit}
                      onClick={() => capture()}
                    >
                      Capture
                    </Button>
                  ) : (
                    <Stack mt={-100} alignItems={'center'}>
                      <Box>
                        <Spinner
                          size="xl"
                          accessibilityLabel="Loading..."
                          color="blue.500"
                        />
                      </Box>
                      <Text fontWeight={'semibold'} textAlign={'center'}>
                        Please Wait
                      </Text>
                    </Stack>
                  )}
                </Box>
              </Stack>
            )}
          </ModalBody>
          <ModalFooter alignItems={'center'}></ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default HrisAttendanceLive;
