import {
  Badge,
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Divider,
  HStack,
  Image,
  Select,
  Spacer,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import BackButtons from '../../Components/Buttons/BackButtons';
import DynamicTable from '../../Components/Table/DynamicTable';
import { createHris, readHris } from '../../Api/Hris';
import useUserStore from '../../Hooks/Zustand/Store';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import { getUserByUid } from '../../Utils/getUser';
import { MdNavigateBefore, MdNavigateNext } from 'react-icons/md';
import { getProjectUsers } from '../../Utils/getProjectUsers';

const HrisLeavePage = () => {
  const [modal, setModal] = useState(false);
  const [detail, setDetail] = useState();
  const [dataLeave, setDataLeave] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingV2, setLoadingV2] = useState(false);
  const [originData, setOriginData] = useState([]);
  const [data, setData] = useState({});
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState('desc');
  const [userId, setUserId] = useState('');
  const [leaveCategory, setLeaveCategory] = useState([]);


  const globalState = useUserStore();
  const toast = useToast();
  const tableHeader = ['employee', 'category', 'begin', 'end', 'status', 'createdAt'];

  const getDataLeave = async () => {
    globalState?.setIsLoading(true);
    const session = {
      headers: globalState?.authFirebaseToken,
      companyId: globalState?.currentCompany,
      projectId: globalState?.currentProject,
    };
    let res;

    try {
      if (userId !== '') {
        res = await readHris(`leave-request/${userId}`, session);
      } else {
        res = await readHris(`leave-request/?page=${page}&sort=${sort}`, session);
      }

      setData(res);

      const userPromises = res?.data?.map(async (x) => {
        const Uid = x?.user_uid;
        // const addUser = user?.find((u) => u.uid === Uid);
        const addUser = await getUserByUid(globalState?.users, Uid);
        if (addUser) {
          x.user_data = addUser;
        }
        return x;
      });
      const leaveDataWithUserData = await Promise?.all(userPromises);

      setDataLeave(leaveDataWithUserData);
      setOriginData(leaveDataWithUserData);
    } catch (error) {
      throw new Error(error.message, 'Failed to send Slack login message');
    } finally {
      globalState?.setIsLoading(false);
    }
  };

  const getFilterStatus = (value) => {
    if (value === '') {
      setDataLeave(originData);
    } else {
      const filterArr = originData.filter((x) => x.current_status === value);
      setDataLeave(filterArr);
    }

    if (value === 'Requested') {
      const filterArr = originData.filter((x) => x.current_status === null);
      setDataLeave(filterArr);
    }
  };

  // console.log(dataLeave, "ini data leave");
  const handleApprove = async (x, role) => {
    const session = {
      headers: globalState?.authFirebaseToken,
      companyId: globalState?.currentCompany,
      projectId: globalState?.currentProject,
    };
    

    setLoading(true);
    const newData = {
      title: 'APPROVE HR',
      reject_note: 'approved',
    };
    const dataManager = {
      title: 'APPROVE MANAGER', 
      reject_note: 'approved',
    };
    try {
      let res;
      if (
        (role === 'manager'|| globalState?.roleCompany === 'owner') &&
        x?.data?.current_status === null) {
        res = await createHris(
          `leave-request-status/${x?.data?.id}/store`,
          session,
          dataManager
        );
      } else if (
        (role === 'hr' ||  globalState?.roleCompany === 'owner') &&
        x?.data?.current_status === 'APPROVE MANAGER'
      ) {
        res = await createHris(
          `leave-request-status/${x?.data?.id}/store`,
          session,
          newData
        );
      }

      if (res?.status) {
        return toast({
          title: 'Success',
          status: 'success',
          description: 'Success Approve',
          isClosable: true,
          duration: 5000
        })
      } else {
        return toast({
          title: 'Failed',
          status: 'error',
          description: res?.message,
          isClosable: true,
          duration: 5000
        })  
        
      }

    } catch (error) {
      toast({
        title: 'Error',
        status: 'Failed to send Slack login message',
        description: error?.message,
        isClosable: true,
        duration: 5000

      })
    } finally {
      setModal(false);
      getDataLeave();
      setLoading(false);
    }
  };

  const handleReject = async (detail, role) => {
    const session = {
      headers: globalState?.authFirebaseToken,
      companyId: globalState?.currentCompany,
      projectId: globalState?.currentProject,
    };

    setLoadingV2(true);
    const newData = {
      title: 'REJECT HR',
      reject_note: 'rejected',
    };
    const dataManager = {
      title: 'REJECT MANAGER',
      reject_note: 'rejected',
    };
    console.log('detail', detail, role, 'role');
    
    try {
      let res
      if (
        (role === 'manager'|| globalState?.roleCompany === 'owner') &&
        detail?.data?.current_status === null) {
        res = await createHris(
          `leave-request-status/${detail?.data?.id}/store`,
          session,
          dataManager
        );
      } else if (
        (role === 'hr' ||  globalState?.roleCompany === 'owner') &&
        detail?.data?.current_status === 'APPROVE MANAGER'
      ) {
        res = await createHris(
          `leave-request-status/${detail?.data?.id}/store`,
          session,
          newData
        );
      }
      if (res?.status) {
        return toast({
          title: 'Success',
          status: 'success',
          description: 'Success Approve',
          isClosable: true,
          duration: 5000
        })
      } else {
        return toast({
          title: 'Failed',
          status: 'error',
          description: res?.message,
          isClosable: true,
          duration: 5000
        })  
        
      }
    } catch (error) {
      throw new Error(error.message, 'Failed to send Slack login message');
    } finally {
      setModal(false);
      getDataLeave();
      setLoadingV2(false);
    }
  };

  let tableData = []
  tableData = dataLeave?.map((data) => {

    const employee = data?.user_data?.name || '';
    const begin = data?.start_date;
    const end = data?.end_date;
    const created = moment
      .parseZone(data?.createdAt || 0)
      .local()
      .format('DD MMMM YYYY - HH:mm');
    const status = data?.current_status;
    const category = leaveCategory?.find((x) => x?.id === data?.leave_request_category_id)?.name

    return {
      data,
      employee: employee,
      category: category,
      begin: begin,
      end: end,
      status:
        status === 'APPROVE MANAGER' ? (
          <Badge borderRadius={'md'} colorScheme="blue">
            PROCESSED
          </Badge>
        ) : status === 'APPROVE HR' ? (
          <Badge borderRadius={'md'} colorScheme="green">
            APPROVED
          </Badge>
        ) : status === 'REJECT MANAGER' ? (
          <Badge borderRadius={'md'} colorScheme="red">
            REJECTED
          </Badge>
        ) : status === 'REJECT HR' ? (
          <Badge borderRadius={'md'} colorScheme="red">
            REJECTED
          </Badge>
        ) : status === null ? (
          <Badge borderRadius={'md'} colorScheme="yellow">
            REQUESTED
          </Badge>
        ) : null,
      createdAt: created,
    };
  });

  const getLeaveCategory = async () => {
    const session = {
      headers: globalState?.authFirebaseToken,
      companyId: globalState?.currentCompany,
      projectId: globalState?.currentProject,
    };

    try {
      const { data } = await readHris('leave-request-category', session);
      setLeaveCategory(data);
    } catch (error) {
      throw new Error(error.message, 'Failed to send Slack login message');
    }
  };

  useEffect(() => {
    getLeaveCategory();

    return () => {
      setLeaveCategory([]);
    };
  }, [globalState?.currentCompany]);

  // console.log(detail, "cire detailss");

  const handleRead = (row) => {
    handleDetail(row);
  };


  useEffect(() => {
    getDataLeave();
    return () => {
      setDataLeave([]);
      setOriginData([]);
    };
  }, [globalState?.currentProject, globalState?.currentCompany, page, sort, userId]);

  const handleDetail = (x) => {
    setDetail(x);
    setModal(true);
  };
  return (
    <Stack>
      <Box py={2}>
        <BackButtons />
      </Box>
      <Card w={'full'} backgroundColor={'white'} shadow={'md'}>
        <CardHeader>
          <HStack
            flexDirection={['column', 'row', 'row']}
            justify={'space-between'}
          >
            <Stack>
              <Text fontSize={'lg'} fontWeight={'medium'}>
                Leave Request
              </Text>
              <Select
                onChange={(e) => setUserId(e.target.value)}
                placeholder='Select user/employee'
              >
                {getProjectUsers(
                  globalState.projects,
                  globalState.currentProject,
                  globalState.users)
                  .map((user, i) => {
                    return <option value={user.id} key={i}>{user.name || user.email}</option>
                  })}
              </Select>
            </Stack>
            <HStack>
              <Text w={'50%'} color={'gray'}>Sorting:</Text>
              <Select
                onChange={(e) => setSort(e.target.value)}
              >
                <option value={'desc'}>Paling Baru</option>
                <option value={'asc'}>Paling Lama</option>
              </Select>
            </HStack>
            <HStack w={['100%', '30%', '30%']}>
              <Text w={'50%'} color={'gray'}>
                Filter Status
              </Text>
              <Select
                onChange={(e) => getFilterStatus(e.target.value)}
                placeholder="select status"
              // color={"lightgray"}
              >
                <option value={'Requested'}>Requested</option>
                <option value={'APPROVE MANAGER'}>Processed</option>
                <option value={'APPROVE HR'}>Approved</option>
                <option value={'REJECT MANAGER' || 'REJECT HR'}>
                  Rejected
                </option>
              </Select>
            </HStack>
          </HStack>
        </CardHeader>

        <CardBody>


          <DynamicTable
            header={tableHeader}
            data={tableData}
            onRead={handleRead}
            action_color="green"
          // action_icon={FaWhatsapp}
          />

          {data?.data?.length < 10 && data?.lastPage === 1 ? null : (
            <HStack justifyContent={'center'} spacing={5} pt={4}>
              {page >= data?.lastPage ? (
                <Button
                  colorScheme="blue"
                  size={'sm'}
                  onClick={() => setPage(page - 1)}
                  isDisabled={page >= data?.lastPage ? false : true}
                >
                  <HStack color="white" spacing={1} pr={3}>
                    <MdNavigateBefore size={23} />
                    <Text>Prev</Text>
                  </HStack>
                </Button>
              ) : null}

              {page < data?.lastPage ? (
                <Button
                  colorScheme="blue"
                  size={'sm'}
                  onClick={() => setPage(page + 1)}
                  isDisabled={page < data?.lastPage ? false : true}
                >
                  <HStack color="white" spacing={1} pl={3}>
                    <Text>Next</Text>
                    <Box>
                      <MdNavigateNext size={23} />
                    </Box>
                  </HStack>
                </Button>
              ) : null}
            </HStack>
          )}
        </CardBody>
      </Card>

      <Modal
        isOpen={modal}
        onClose={() => setModal(false)}
        motionPreset="slideInBottom"
        isCentered
        size={'lg'}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Leave Request Detail</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box align={'center'}>
              <Image width={'auto'} src={detail?.data?.attachments}cursor={'pointer'} onClick={() => window.open(detail?.data?.attachments)} />
              {/* <iframe src={detail?.data?.attachments} width={'auto'} cursor={'pointer'} onClick={() => window.open(detail?.data?.attachments)} /> */}
            </Box>
            <HStack justify={'space-between'} my={2}>
              <Text>Category</Text>
              <Text textTransform={'capitalize'}>{leaveCategory.find((x) => x?.id === detail?.data?.leave_request_category_id)?.name}</Text>
            </HStack>
            <HStack justify={'space-between'} my={2}>
              <Text>Employee</Text>
              <Text textTransform={'capitalize'}>{detail?.employee}</Text>
            </HStack>
            <HStack justify={'space-between'} my={2}>
              <Text>Begin Date</Text>
              <Text>{detail?.begin}</Text>
            </HStack>
            <HStack justify={'space-between'} my={2}>
              <Text>End Date</Text>
              <Text>{detail?.end}</Text>
            </HStack>
            <HStack justify={'space-between'} my={2}>
              <Text>Status</Text>
              <Text>
                {detail?.data?.current_status === 'APPROVE MANAGER' ? (
                  <Badge borderRadius={'md'} colorScheme="blue">
                    PROCESSED
                  </Badge>
                ) : (
                  ''
                )}
                {detail?.data?.current_status === 'APPROVE HR' ? (
                  <Badge borderRadius={'md'} colorScheme="green">
                    APPROVED
                  </Badge>
                ) : (
                  ''
                )}
                {detail?.data?.current_status === 'REJECT MANAGER' ? (
                  <Badge borderRadius={'md'} colorScheme="red">
                    REJECT MANAGER
                  </Badge>
                ) : (
                  ''
                )}
                {detail?.data?.current_status === 'REJECT HR' ? (
                  <Badge borderRadius={'md'} colorScheme="red">
                    REJECT HR
                  </Badge>
                ) : (
                  ''
                )}
                {detail?.data?.current_status === null ? (
                  <Badge borderRadius={'md'} colorScheme="yellow">
                    REQUESTED
                  </Badge>
                ) : (
                  ''
                )}
              </Text>
            </HStack>
            <HStack justify={'space-between'} my={2}>
              <Text>Created At</Text>
              <Text>{detail?.createdAt}</Text>
            </HStack>
          </ModalBody>
          <Divider />
          
          <ModalFooter>
            <HStack>
              {(globalState?.roleCompany === 'owner' || globalState?.roleProject === 'managers') && (
                detail?.data?.current_status === null &&
                <HStack>
                  <Text color={'red'} fontSize={12}>Manager have to approved/reject this request</Text>
                  <Spacer/>
                  <DynamicButton
                    action="custom"
                    title="Reject"
                    color={'red'}
                    variant={'solid'}
                    onClick={() => handleReject(detail, 'manager')}
                    disabled={detail?.data?.current_status === 'APPROVE MANAGER' ? true : false}
                  />
                  <DynamicButton
                    action="custom"
                    title="Approve Manager"
                    color={'green'}
                    variant={'solid'}
                    // onClick={() => console.log('test handle approve')}
                    onClick={() => handleApprove(detail, 'manager')}
                    disabled={detail?.data?.current_status === 'APPROVE MANAGER' ? true : false}
                  />
                </HStack>
              )}
              {(globalState?.roleCompany === 'owner' || globalState?.roleProject === 'hr') && (
                detail?.data?.current_status === 'APPROVE MANAGER' &&
                <HStack>
                  <Text color={'red'} fontSize={12}>HR have to approved/reject this request</Text>
                  <Spacer/>
                  <DynamicButton
                    action="custom"
                    title="Reject"
                    color={'red'}
                    variant={'solid'}
                    onClick={() => handleReject(detail, 'hr')}
                    disabled={detail?.data?.current_status === 'APPROVE HR' ? true : false}
                  />
                  <DynamicButton
                    action="custom"
                    title="Approve HR"
                    color={'green'}
                    variant={'solid'}
                    onClick={() => handleApprove(detail, 'hr')}
                    disabled={detail?.data?.current_status === 'APPROVE HR' ? true : false}
                  />
                </HStack>
              )}
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

export default HrisLeavePage;
