/* eslint-disable react/prop-types */
import { Heading, Stack, Text } from '@chakra-ui/react';
import moment from 'moment';
import React, { useEffect, useState } from 'react'

function LiveTime({color, size}) {
  const [currentTime, setCurrentTime] = useState(
    moment().format('kk : mm : ss')
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment().format('kk : mm : ss'));
    }, 1000); // Update every 1 second
    return () => {
      clearInterval(interval);
    };
  }, []);


  return (
    <Stack spacing={0}>
      <Heading color={color} size={size || 'lg'} fontWeight="extrabold">{currentTime}</Heading>
      <Text fontSize={'xs'} color={color} fontWeight={500} >{moment().format('llll')}</Text>
    </Stack>
  )
}

export default LiveTime