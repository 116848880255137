/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Spacer,
  Center,
  Divider,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import { useDropzone } from 'react-dropzone';
import AppTableComponentData from '../../Components/AppComponent/AppTableComponentData';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import useUserStore from '../../Hooks/Zustand/Store';
import { createHris, readHris } from '../../Api/Hris';
import UploadFileToDropbox from '../../Utils/UploadFileToDropbox';
import { getSingleDocumentFirebase } from '../../Api/firebaseApi';
import { decryptToken } from '../../Utils/encrypToken';
import MediaUpload from '../../Components/MediaUpload/MediaUpload';

const HrisAttendanceLeave = () => {
  const [modal, setModal] = useState(false);
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [loadFile, setLoadFile] = useState(null);
  const [requested, setRequested] = useState(null);
  const [approved, setApproved] = useState(null);
  const [rejected, setRejected] = useState(null);
  const [leaveCategory, setLeaveCategory] = useState([]);
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [selectedLeave, setSelectedLeave] = useState(null);
  const [leaveReason, setLeaveReason] = useState(null);
  const [progress, setProgress] = useState(0)

  const globalState = useUserStore();

  const getLeaveCategory = async () => {
    const session = {
      headers: globalState?.authFirebaseToken,
      companyId: globalState?.currentCompany,
      projectId: globalState?.currentProject,
    };

    try {
      const { data } = await readHris('leave-request-category', session);
      setLeaveCategory(data);
    } catch (error) {
      throw new Error(error.message, 'Failed to send Slack login message');
    }
  };

  useEffect(() => {
    getLeaveCategory();

    return () => {
      setLeaveCategory([]);
    };
  }, [globalState?.currentCompany]);

  const getLeaveRequest = async () => {
    globalState?.setIsLoading(true);
    const session = {
      headers: globalState?.authFirebaseToken,
      companyId: globalState?.currentCompany,
      projectId: globalState?.currentProject,
    };

    try {
      const { data } = await readHris(
        `leave-request/${globalState?.uid}`,
        session
      );
      const dataFind = data?.filter((x) => x?.user_uid === globalState?.uid);

      const request = dataFind?.filter(
        (x) =>
          x?.current_status === null || x?.current_status === 'APPROVE MANAGER'
      );
      const approve = dataFind?.filter(
        (x) => x?.current_status === 'APPROVE HR'
      );
      const reject = dataFind?.filter(
        (x) =>
          x?.current_status === 'REJECT MANAGER' ||
          x?.current_status === 'REJECT HR'
      );
      setRequested(request);
      setApproved(approve);
      setRejected(reject);
    } catch (error) {
      throw new Error(error.message, 'Failed to send Slack login message');
    } finally {
      globalState?.setIsLoading(false);
    }
  };

  useEffect(() => {
    getLeaveRequest();

    return () => {
      setRequested(null);
      setApproved(null);
      setRejected(null);
    };
  }, []);
  const num = parseInt(selectedLeave);
  const dataBalance = leaveCategory?.filter((x) => x.id === num);

  const handleAdd = () => {
    setModal(true);
  };
  const handleFile = (data) => {
    setLoadFile(data);
  };
  const handleCancel = () => {
    setLoadFile(null);
  };
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleFile,
    multiple: false,
  });

  const submit = async () => {
    if (
      selectedLeave === null ||
      start === null ||
      end === null ||
      loadFile === null
    ) {
      toast({
        title: 'All input should be filled',
        duration: 3000,
        status: 'error',
      });
    } else {
      setLoading(true);
      const session = {
        headers: globalState?.authFirebaseToken,
        companyId: globalState?.currentCompany,
        projectId: globalState?.currentProject,
      };

      const dataCompany = 'edrus';
      const dataProject = 'edrus';
      const dataModule = 'attandance';
      // Membuat objek FormData untuk mengirim data gambar
      const formData = new FormData();
      formData.append('companyId', dataCompany);
      formData.append('projectId', dataProject);
      formData.append('module', dataModule);
      formData.append('file', loadFile[0], 'file.pdf');
      const token = await getSingleDocumentFirebase('token', 'dropbox');
      const decryptResult = decryptToken(`${token?.access_token}`);


      const parentPath = `/${globalState?.currentCompany}/${globalState?.currentProject}/hris/leave`;
      try {
        // const response = await axios.post(
        //   'https://deoapp-backend-awfnqqp6oq-et.a.run.app/upload_file',
        //   formData,
        //   {
        //     headers: {
        //       'Content-Type': 'multipart/form-data',
        //     },
        //   }
        // );
        // const response = (await axios.post('https://new-apiv2.importir.com/api/storage?directory=general', formData)).data;
        // if (!response.status) {
        //   toast({
        //     title: 'Deoapp.com',
        //     description: response.message,
        //     status: 'error',
        //     position: 'top-right',
        //     isClosable: true,
        //     duration: 3000
        //   });
        //   return
        // }
        const response = await UploadFileToDropbox(loadFile[0], parentPath, decryptResult, setProgress, progress)
        if (response.link) {
          // console.log('File berhasil diunggah:', response.data.link.link);

          const linkImage = response.link;
          const idLeave = parseInt(selectedLeave);
          const dataPost = {
            leave_request_category_id: idLeave,
            start_date: start,
            end_date: end,
            attachments: [linkImage],
            leave_reason: leaveReason
          };
          try {
            const res = await createHris('leave-request', session, dataPost);
            if (res.status === true) {
              toast({
                status: 'success',
                description: res.message,
                duration: 2000,
              });
            } else {
              throw new Error('Failed to send Slack login message');
            }
          } catch (error) {
            throw new Error(
              error.message,
              'Failed to send Slack login message'
            );
          }
        } else {
          throw new Error('Failed to send Slack login message');
        }
      } catch (error) {
        throw new Error(error.message, 'Failed to send Slack login message');
      } finally {
        setLoading(false);
        setSelectedLeave(null);
        setModal(false);
        getLeaveRequest();
      }
    }
  };
  return (
    <Stack my={5}>
      <Tabs isFitted variant="enclosed" bg={'white'} borderRadius={'md'}>
        <TabList mb="1em">
          <Tab fontWeight={500}>Request</Tab>
          <Tab fontWeight={500}>Approved</Tab>
          <Tab fontWeight={500}>Rejected</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <HStack justify={'space-between'}>
              <Spacer />
              <DynamicButton
                title="Request"
                action="create"
                onClick={handleAdd}
              />
            </HStack>
            {requested?.length === 0 || !requested ? (
              <Center py={5}>
                <Text color={'darkgrey'}>You have not requested any leave</Text>
              </Center>
            ) : (
              <AppTableComponentData data={requested} leaveCategory={leaveCategory} />
            )}
          </TabPanel>
          <TabPanel>
            {approved?.length === 0 || !approved ? (
              <Center py={5}>
                <Text color={'darkgrey'}>No leave approved</Text>
              </Center>
            ) : (
              <AppTableComponentData data={approved} leaveCategory={leaveCategory} />
            )}
          </TabPanel>
          <TabPanel>
            {rejected?.length === 0 || !rejected ? (
              <Center py={5}>
                <Text color={'darkgrey'}>No leave rejected</Text>
              </Center>
            ) : (
              <AppTableComponentData data={rejected} leaveCategory={leaveCategory} />
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>

      <Modal isOpen={modal} onClose={() => setModal(false)} size={'4xl'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Request Leave</ModalHeader>
          <ModalBody>
            <HStack w={'full'} mb={3}>
              <Stack w={'50%'}>
                <Text fontSize={'xs'}>Select Leave Category</Text>
                <Select
                  placeholder="select leave category"
                  onChange={(e) => setSelectedLeave(e.target.value)}
                >
                  {leaveCategory?.map((x, i) => (
                    <option value={x.id} key={i}>{x?.name}</option>
                  ))}
                </Select>
              </Stack>
              <Stack w={'50%'}>
                <Text fontSize={'xs'}>Leave Balance</Text>
                {dataBalance === undefined ? (
                  <Input disabled type="number" value={0} />
                ) : (
                  <Input
                    disabled
                    type="number"
                    value={dataBalance[0]?.initial_balance}
                  />
                )}
              </Stack>
            </HStack>
            <HStack my={3}>
              <Stack w={'33%'}>
                <Text fontSize={'xs'}>Start</Text>
                <Input type="date" onChange={(e) => setStart(e.target.value)} />
              </Stack>
              <Stack w={'33%'}>
                <Text fontSize={'xs'}>End</Text>
                <Input type="date" onChange={(e) => setEnd(e.target.value)} />
              </Stack>
              <Stack w={'33%'}>
                <Text fontSize={'xs'}>Request Balance</Text>
                <Input type="number" />
              </Stack>
            </HStack>
            <Text fontSize={'xs'} mt={3}>
              Add Attachment
            </Text>
            <MediaUpload getRootProps={getRootProps} getInputProps={getInputProps} loadFile={loadFile} handleCancel={handleCancel} />

            <Stack my={3}>
              <Text fontSize={'xs'}>Describe Reason</Text>
              <Textarea
                mb={5}
                onChange={(e) => setLeaveReason(e.target.value)}
              />
            </Stack>

            <Divider />
          </ModalBody>
          <ModalFooter gap={3}>
            <Button colorScheme="red" onClick={() => setModal(false)}>
              Cancel
            </Button>
            <Button colorScheme="blue" onClick={submit} isLoading={loading}>
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

export default HrisAttendanceLeave;
