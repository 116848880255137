/* eslint-disable react/prop-types */
import { Badge, Stack, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'

const AppTableComponentData = ({ data, leaveCategory }) => {
  
  return (
    <Stack
      bgColor="white"
      spacing={1}
      borderRadius="xl"
      p={3}
      m={[1, 1, 5]}
      shadow="md"
      overflowX={'auto'}
    >
      <Table variant={'striped'} colorScheme="gray">
        <Thead>
          <Tr>
            <Th>Created At</Th>
            <Th>Category</Th>
            <Th>Start</Th>
            <Th>End</Th>
            <Th>Status</Th>
            <Th>Attachment</Th>
          </Tr>
        </Thead>
        <Tbody fontSize="sm">
          {data?.length > 0 && data?.map((x, index) => (
            <Tr key={index}>
              <Td>{moment.parseZone((x?.createdAt?.seconds * 1000) || (x?.createdAt)).local().format('DD MMMM YYYY - HH:mm')}</Td>
              <Td>{leaveCategory?.find((y) => y?.id === x?.leave_request_category_id)?.name}</Td>
              {x?.clock_in ?
                <>
                  <Td>{x?.clock_in}</Td>
                  <Td>{x?.clock_out}</Td>
                </> : x?.start_hour ?
                  <>
                    <Td>{x?.start_hour}</Td>
                    <Td>{x?.end_hour}</Td>
                  </> :
                  <>
                    <Td>{x?.start_date}</Td>
                    <Td>{x?.end_date}</Td>
                  </>
              }

              <Td>
                {x.current_status === 'APPROVE MANAGER' ? <Badge colorScheme='blue'>PROCESSED</Badge> : ''}
                {x.current_status === 'APPROVE HR' ? <Badge colorScheme='green'>APPROVED</Badge> : ''}
                {x.current_status === 'REJECT MANAGER' ? <Badge colorScheme='red'>REJECTED</Badge> : ''}
                {x.current_status === 'REJECT HR' ? <Badge colorScheme='red'>REJECTED</Badge> : ''}
                {x.current_status === null ? <Badge colorScheme='yellow'>REQUESTED</Badge> : ''}
              </Td>

              {/* <Td fontWeight={'bold'}>{x?.current_status}</Td> */}
              <Td>
                <Link to={x?.attachments || x?.attachment} target='_blank'>
                  <Text color="blue" _hover={{textDecoration:'underline'}}>
                See Attachment
                  </Text>
                </Link>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Stack>
  )
}

export default AppTableComponentData