import { Spinner, Flex, useColorMode, Text, Stack } from '@chakra-ui/react';
import React from 'react';
import useUserStore from '../../Hooks/Zustand/Store';

const LoadingOverlay = () => {
  const globalState = useUserStore();
  const { colorMode } = useColorMode();


  return (
    globalState.isLoading && (

      <Flex
        position="fixed"
        top="0"
        left="0"
        width="100%"
        height="100%"
        backgroundColor={colorMode === 'dark' ? 'blackAlpha.900' : 'rgba(255, 255, 255, 0.9)'}
        zIndex="9999"
        justifyContent="center"
        alignItems="center"
      >
        <Stack alignItems={'center'} spacing={2} justifyContent={'center'}>
          <Stack alignItems={'center'} spacing={1} justifyContent={'center'}>
            <Text fontWeight={1000} fontStyle={'revert'} textDecoration={'AppWorkspace'} fontSize={'lg'} letterSpacing={2} textTransform={'uppercase'}>deoapp</Text>
            <Text fontWeight={'500'} fontStyle={'revert'}  fontSize={'xs'} letterSpacing={1} textTransform={'uppercase'}>all in one project management</Text>
          </Stack>
          <Stack>
            <Spinner
              accessibilityLabel="Loading..."
              thickness='5px'
              speed='0.5s'
              emptyColor='gray.200'
              color='blue.500'
              size='xl'
            />
          </Stack>


        </Stack>
      </Flex>
    )
  );
};

export default LoadingOverlay;
