/* eslint-disable react/prop-types */
import {Button, Heading, Highlight, HStack, Spacer, Stack, Tag, Text } from '@chakra-ui/react'
import React from 'react'
import {  BsCheckLg } from 'react-icons/bs'
import { Rupiah } from '../../Utils/NumberUtils'

function PricingPage({ features, discount  }) {


  const handleWhatsapp = () => {
    const message = encodeURIComponent('Hi Admin Deoapp for Enterprise,\n\nSilahkan tinggalkan pesanmu, dan Team Support kami akan segera membalas 😊');
    const source = encodeURIComponent(window.location.href);
    const url = `https://api.whatsapp.com/send?phone=6281241678382&text=${message}%0A%0ASource:%20${source}`;
    window.open(url, '_blank');
  }

  return (
    <Stack alignItems={'center'} justifyContent="center" spacing={2}>
      <Text fontSize={'lg'} color="gray.600" textAlign={'center'}>Pick the right plan for your business</Text>{/* <AppSponsor /> */}
      <Heading>My monthly sales is up to </Heading>

      {discount > 0 ? (
        <Stack>
          <HStack>
            <Tag bgColor={'red.600'} p={2}>
              <Heading fontWeight={'extrabold'} as="del" color={'white'}>{Rupiah(features?.sales)}{features?.value === 120 ? '+' : ''}</Heading>
            </Tag>
          </HStack>

          <HStack>
            <Tag bgColor={'blue.600'} p={2}>
              <Heading fontWeight={'extrabold'} color={'white'}>{Rupiah(features?.sales - (features?.sales * discount / 100))}{features?.value === 120 ? '+' : ''}</Heading>
            </Tag>
            <Heading>All in</Heading>
          </HStack>
        </Stack>
      ) : (
        <HStack>
          <Tag bgColor={'blue.600'} p={2}>
            <Heading fontWeight={'extrabold'} color={'white'}>{Rupiah(features?.sales)}{features?.value === 120 ? '+' : ''}</Heading>
          </Tag >
          <Heading>All in</Heading>
        </HStack >
      )
      }

      {/* <Slider defaultValue={20} min={0} max={120} step={20} onChange={(val) => handleSlider(val)}>
        <SliderTrack bg='red.100'>
          <Box position='relative' right={10} />
          <SliderFilledTrack bg='blue' />
        </SliderTrack>
        <SliderThumb boxSize={6}>
          <Box color='blue' as={MdGraphicEq} />

        </SliderThumb>
      </Slider> */}

      <Stack width='full'>
        <Heading textAlign='center' fontWeight={'bold'} fontSize='lg'>
          {features?.value !== 120 ?
            (Rupiah(features?.price - (features?.price * discount / 100)) + '/month')
            :
            <Button bgColor={'blue.600'} onClick={() => handleWhatsapp()} color="white">Contact Sales</Button>
          }
        </Heading>

        <Text textAlign='center' m='1'>
          <Highlight
            query={parseFloat(features?.price / features?.sales * 100).toFixed(2) + '%'}
            styles={{ px: '2', py: '1', rounded: 'full', bg: 'red.100', fontWeight: 500 }}
          >
            {features?.value !== 120 ?
              parseFloat(features?.price / features?.sales * 100).toFixed(2) + '% From your sales'
              : ''}
          </Highlight>

        </Text>
        <Stack bgColor={'blue.600'} p={5} color="white" borderRadius={'lg'}>
          <HStack >
            <BsCheckLg color="white" />
            <Text color={'gray.100'}>Management</Text>
            <Spacer />
            <Text fontWeight={'bold'}>UNLIMITED</Text>
          </HStack>
          <HStack>
            <BsCheckLg color="white" />
            <Text color={'gray.100'}>Kanban</Text>
            <Spacer />
            <Text fontWeight={'bold'}>UNLIMITED</Text>
          </HStack>
          <HStack>
            <BsCheckLg color="white" />
            <Text color={'gray.100'}>Mind Map</Text>
            <Spacer />
            <Text fontWeight={'bold'}>UNLIMITED</Text>
          </HStack>
          <HStack>
            <BsCheckLg color="white" />
            <Text color={'gray.100'}>Flow Chart</Text>
            <Spacer />
            <Text fontWeight={'bold'}>UNLIMITED</Text>
          </HStack>
          <HStack>
            <BsCheckLg color="white" />

            <Text color={'gray.100'}>HRIS (Attendance, Payroll, ext)</Text>
            <Spacer />
            <Text fontWeight={'bold'}>UNLIMITED</Text>
          </HStack>
        </Stack>
      </Stack>
    </Stack >
  )
}

export default PricingPage